import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import Div from "@components/elements/div"
import GradientText from "@components/common/gradient-text"

const Compare = ({ data }) => {
  return (
    <Layout
      title={`Compare With Klipfolio`}
      description={`Compare Klipfolio PowerMetrics with other apps.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex gap="6rem">
          <Div margin="8rem 0 0">
            <Container>
              <Heading
                center
                as="h2"
                margin="0 auto"
                padding="0 0 4rem"
              >
                Browse PowerMetrics<br />
                <GradientText variant="powermetrics">
                  Comparisons
                </GradientText>
              </Heading>
              <Grid
                gap="2rem"
                columns={"repeat(3, 1fr)"}
                columnsMd="repeat(2, 1fr)"
                columnsSm="1fr"
                margin="0 0 4rem"
              >
                {data.allAlternate.edges.map(({ node: alternate }, i) => {
                  return (
                    <Flex
                      gap="1rem"
                      padding="2rem"
                      background="#EDF3FD"
                      borderRadius="1rem"
                      key={`alternate-${i}`}
                    >
                      <Anchor link={`${alternate.slug}`} fontWeight={700} fontSize="1.4rem">
                        {alternate.seo.title}
                      </Anchor>
                      <Paragraph fontSize={"1rem"}>
                        {alternate.seo.description}
                      </Paragraph>
                      <Anchor margin="auto 0 0" link={`${alternate.slug}`}>
                        Learn more
                      </Anchor>
                    </Flex>
                  )
                })}
              </Grid>
            </Container>
          </Div>
        </Flex>
      </Container>
    </Layout>
  )
}

Compare.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query CompareQuery {
    allAlternate(limit: 1000, filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          h1
          description
          seo {
            title
            description
          }
        }
      }
    }
  }
`

export default Compare
